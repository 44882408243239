<template>
	<div>
		<!-- <div class="form-group">
			<label for="horse_nom">{{ $t('monte.etalon') }} *</label>
			<e-select
				v-model="stallion_model"
				track-by="horse_id"
				label="horse_nom"
				:placeholder="labelTitleStallion"
				:selectedLabel="selectedLabelStallion"
				:options="stallions"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingStallion"
				:disabled="isStallionDisabled"
				:class="{ 'is-invalid': errors && errors.indexOf('stallion') > -1 }"
			></e-select>
		</div> -->

		<!-- <div class="form-group">
			<label for="model_label">{{ $t('monte.modele_contrat') }} *</label>
			<e-select
				v-model="modele_model"
				id="model_id"
				track-by="model_id"
				label="model_label"
				:placeholder="labelTitleModeles"
				:selectedLabel="selectedLabelModeles"
				:options="modeles"
				:allow-empty="false"
				:show-labels="false"
				:disabled="disabled"
				:loading="loadingModele"
				:class="{ 'is-invalid': errors && errors.indexOf('modele') > -1 }"
			></e-select>
		</div> -->
	 	<!-- <div class="form-group">
			<label for="id">{{ $t('monte.type_monte') }} *</label>
			<e-select
				v-model="type_monte_model"
				id="id"
				track-by="id"
				label="name"
				:placeholder="labelTitleConditionsMonte"
				:selectedLabel="selectedLabelConditionsMonte"
				:options="type_monte_tab"
				:allow-empty="false"
				:show-labels="false"
				:loading="loadingTypeMonte"
				:disabled="disabled"
				:class="{ 'is-invalid': errors && errors.indexOf('modele') > -1 }"
			></e-select>
		</div> -->

		<TableAvenantArticle 
			ref="TableAvenantArticle" 
			:tiers.sync="tiers"
			:tiers_tab.sync="tiers_tab"
			:contractconfig_id.sync="contractconfig_id"
			:typemonte.sync="type_monte_model"
			:avenant="avenant"
			:conditions_avenant_articles="conditions"
			:disabled="disabled"
			@update:conditions_avenant_articles="maj_conditions">
				
		</TableAvenantArticle>
	</div>
</template>

<script type="text/javascript">
	
	import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import ContractMixin from "@/mixins/Contract.js"
	import ModelMixin from "@/mixins/Model.js"

	export default {
		name: 'ChangementEtalon',
		mixins: [Navigation, Tools, ContractMixin, ModelMixin],
		props:[
		    'contract_id',
		    'season_id',
		    'stallion',
		    'modele',
		    'contractconfig_id',
		    'avenant',
		    'type_monte',
		    'tiers',
		    'tiers_tab',
		    'contract_config',
		    'conditions',
		    "disabled",
		    "reload_stallion"
		],
		data () {
			return {
                stallions: [],
                modeles: [],
                type_monte_tab: [],
                conditions_local: [],
                loadingStallion: false,
                combinaison_season_stallion: "",
                loadingModele: false,
				loadingTypeMonte: false,
				force_reload: true,
                modele_model: null,
                type_monte_model: null,
                errors: [],
                contracts_config: null,
				labelTitleStallion: this.getTrad("monte.rechercher_stallion"),
				selectedLabelStallion: this.getTrad("global.selected_label"),
				labelTitleModeles: this.getTrad("monte.rechercher_modele"),
				selectedLabelModeles: this.getTrad("global.selected_label"),
				labelTitleConditionsMonte: this.getTrad("monte.rechercher_conditions_monte"),
				selectedLabelConditionsMonte: this.getTrad("global.selected_label"),
			}
		},

		computed: {
			stallion_model: {
				get() {
					return this.stallion
				},
				set(val) {
					this.$emit('update:stallion', val)
				}
			},
			isStallionDisabled() {
				return this.disabled || this.change !== 'ETALON'
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.reloadStallion()
				this.reloadModelContractConfig()
			},

			async reloadStallion(){
				this.loadingStallion = true

				let stallion_ids = []
				this.stallions = []

				this.stallion_model = null
				this.modele_model = null

				this.contracts_config = await this.loadContractsConfigBySeason(this.season_id)
				for (let i = 0; i < this.contracts_config.length; i++) {
					if(stallion_ids.indexOf(this.contracts_config[i].horse.horse_id) == -1)
					{
						stallion_ids.push(this.contracts_config[i].horse.horse_id)
						this.stallions.push(this.contracts_config[i].horse)

						//Préselection du cheval s'il correspond au contractconfig, sauf si je suis en avenant saison
						if(this.contractconfig_id == this.contracts_config[i].contractconfig_id && this.reload_stallion !== false)
						{
							this.stallion_model = this.contracts_config[i].horse
						}
					}
				}

				this.loadingStallion = false
			},

			async reloadModelContractConfig(){

				this.loadingModele = true
				this.loadingTypeMonte = true

				this.type_monte_model = null
				this.type_monte_tab = []

				if(this.stallion_model != null){
					this.contract_config_local = this.contracts_config.filter(contract => {
						return contract.contractconfig_season == this.season_id && contract.contractconfig_horse == this.stallion_model.horse_id
					})[0]
					this.modeles = []
					this.modele_model = null
					this.force_reload = true

					this.$emit("update:contract_config", this.contract_config_local)
					if(this.contract_config_local != undefined)
					{
						this.$emit("update:contractconfig_id", this.contract_config_local.contractconfig_id)

						this.modeles = await this.loadAllModel(this.contract_config_local.contractconfig_id, ["contract_config"])
						// this.$emit("update:modele", this.modeles[0])
						this.modele_model = this.modeles[0]

						//Je vais chercher les type de montes de ce contract config
						let type_monte = await this.getContractConfigTypeMonte(this.contract_config_local.contractconfig_id)
						for (let i = 0; i < type_monte.length; i++) {
							this.type_monte_tab.push({
								id: type_monte[i].contractconfigtypemonte_id,
								name: this.getTrad(type_monte[i].contract_type_monte.contracttypemonte_label) + " - " + type_monte[i].contractconfigtypemonte_label,
							})

							let selected = false

							

							if(i == 0 || this.avenant.avenant_typemonte == type_monte[i].contractconfigtypemonte_id)
							{
								selected = true
							}

							if(this.avenant.avenant_typemonte == type_monte[i].contractconfigtypemonte_id)
							{
								this.force_reload = false
							}

							if(selected)
							{
								this.type_monte_model = {
									id: type_monte[i].contractconfigtypemonte_id,
									name: this.getTrad(type_monte[i].contract_type_monte.contracttypemonte_label) + " - " + type_monte[i].contractconfigtypemonte_label,
								}
							}
						}

					}
				}
				
				this.loadingTypeMonte = false
				this.loadingModele = false
			},
			maj_conditions(val){
				this.$emit("update:conditions", val)
			}
		},
		watch: {
		    'input_text' (val) {
		        this.$emit('update:answer', { val: val, type: 'ChangementEtalon' })
		    },
			season_id(val){
				this.reloadStallion()
			},
			stallion_model(val){
				this.reloadModelContractConfig()
			},
			modele_model(val){
				this.$emit("update:modele", val)
			},
			type_monte_model(val, old_val){
				
				if(this.force_reload == true){
					this.$refs["TableAvenantArticle"].reloadCondition(true)
				}
				else if(val != null)
				{
					this.$refs["TableAvenantArticle"].reloadCondition(false)	
				}

				if(val == null){
					this.$refs["TableAvenantArticle"].resetConditions()
				}

				this.$emit("update:type_monte", val)
			}
		},

		components: {
			TableAvenantArticle : () => import('@/components/Contract/Type/TableAvenantArticle'),
		}
	}

</script>